import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSyringe,
  faHeartbeat,
  faBan
} from '@fortawesome/free-solid-svg-icons';
import ScrollableAnchor from 'react-scrollable-anchor';

const Container = styled.div`
  width: 100%;
  height: 630px;
  background-color: #2f89fc;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;

  > *:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

const HeaderText = styled.h1`
  color: white;
  text-align: center;
`;

const SummaryText = styled.p`
  font-weight: normal;
  color: white;
  text-align: center;
`;

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;

  > *:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

const ServiceBubble = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: white;
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
`;

const BubbleIcon = styled(FontAwesomeIcon)`
  color: #2f89fc;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;

  > *:not(:first-child) {
    margin-left: 3rem;
  }
`;

const LocationSection = (): JSX.Element => (
  <ScrollableAnchor id="services">
    <Container>
      <SectionContainer>
        <ServiceContainer>
          <ServiceBubble>
            <BubbleIcon icon={faSyringe} size="2x" />
          </ServiceBubble>
        </ServiceContainer>
        <ServiceContainer>
          <ServiceBubble>
            <BubbleIcon icon={faHeartbeat} size="2x" />
          </ServiceBubble>
        </ServiceContainer>
        <ServiceContainer>
          <ServiceBubble>
            <BubbleIcon icon={faBan} size="2x" />
          </ServiceBubble>
        </ServiceContainer>
      </SectionContainer>
      <SectionContainer>
        <ServiceContainer>
          <HeaderText>General Anesthesia</HeaderText>
          <SummaryText>
            Physician-only services with licensed Anesthesiologist
          </SummaryText>
        </ServiceContainer>
        <ServiceContainer>
          <HeaderText>Monitored Anesthesia Care</HeaderText>
          <SummaryText>
            Perfect for short, minor procedures that can be performed in an OR or office-based setting
          </SummaryText>
        </ServiceContainer>
        <ServiceContainer>
          <HeaderText>Regional Nerve Blocks</HeaderText>
          <SummaryText>
            Provides patients with robust postoperative pain relief and reduces time to ambulation
          </SummaryText>
        </ServiceContainer>
      </SectionContainer>
    </Container>
  </ScrollableAnchor>
);

export default LocationSection;
