import React from 'react';

import styled from 'styled-components';

const FooterContainer = styled.div`
  background-color: #3e3e3e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
`;

const FooterText = styled.p`
  color: #d2d2d2;
`;

const Footer = (): JSX.Element => (
  <FooterContainer>
    <FooterText>
      Copyright © 2019 All rights reserved | Sunshine Anesthesia, LLC
    </FooterText>
  </FooterContainer>
);

export default Footer;
