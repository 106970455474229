import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ScrollableAnchor from 'react-scrollable-anchor';

const Container = styled.div`
  width: 100%;
  height: 630px;
  background-color: #2f89fc;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem;
  box-sizing: border-box;

  > *:not(:first-child) {
    margin-top: 5rem;
  }
`;

const HeaderText = styled.h1`
  color: white;
`;

const ContactText = styled.a`
  font-size: 1.5em;
  color: white;
  text-decoration: none;

  :hover {
    opacity: 0.7;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:first-child) {
    margin-top: 3rem;
  }
`;

const ContactBubble = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: white;
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
`;

const BubbleIcon = styled(FontAwesomeIcon)`
  color: #2f89fc;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 3rem;
  }
`;

const ContactUsSection = (): JSX.Element => (
  <ScrollableAnchor id="contact">
    <Container>
      <HeaderText>{"Let's Get In Touch"}</HeaderText>
      <RightContainer>
        <ContactContainer>
          <ContactBubble>
            <BubbleIcon icon={faPhone} size="2x" />
          </ContactBubble>
          <ContactText href="tel:9419328085">(941) 932-8085</ContactText>
        </ContactContainer>
        <ContactContainer>
          <ContactBubble>
            <BubbleIcon icon={faEnvelope} size="2x" />
          </ContactBubble>
          <ContactText href="mailto:michael@sunshine-anesthesia.com">
            Michael@Sunshine-Anesthesia.com
          </ContactText>
        </ContactContainer>
      </RightContainer>
    </Container>
  </ScrollableAnchor>
);

export default ContactUsSection;
