import React from 'react';
import styled from 'styled-components';
import ScrollableAnchor from 'react-scrollable-anchor';

import IosImage from 'assets/images/feature-image.jpeg';

const Container = styled.div`
  width: 100%;
  height: 630px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > :not(:first-child) {
    margin-left: 3rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10.5%;
  flex: 1;

  > *:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

const HeaderText = styled.h1`
  color: #2f89fc;
`;

const SummaryText = styled.p`
  font-weight: normal;
`;

const QuoteButton = styled.a`
  background-color: #2f89fc;
  border: 1px solid #2f89fc;
  color: white;
  padding: 1rem;
  border-radius: 2rem;
  transition: 0.15s ease-in-out;
  text-decoration: none;

  :hover {
    background-color: white;
    color: #2f89fc;
  }
`;

const IosImgContainer = styled.img`
  width: 60%;
`;

const AboutSection = (): JSX.Element => (
  <ScrollableAnchor id="about">
    <Container>
      <TextContainer>
        <HeaderText>Anesthesia Consulting Services</HeaderText>
        <SummaryText>
          Sunshine Anesthesia specializes in providing surgeons, dentists, and medical practitioners with anesthesia services including: GA, MAC, and neuromuscular blocks. Our focus is on providing safe, cost effective, and efficient services to meet our individual clients needs.
        </SummaryText>
        <QuoteButton href="mailto:michael@sunshine-anesthesia.com">Get A Quote</QuoteButton>
      </TextContainer>
      <IosImgContainer src={IosImage} />
    </Container>
  </ScrollableAnchor>
);

export default AboutSection;
